import Flow from '@flowjs/flow.js';
import store from '../store/index';

class UploadService {
  constructor(opts = {}) {
    this.button = opts.button;
    this.task_id = opts.task_id || null;
    this.endpoint = opts.endpoint;
    this.fileIds = [];
    this.flow = new Flow({
      target: this.endpoint,
      testChunks: false,
      allowDuplicateUploads: true,
      query: {
        task_id: this.task_id,
      },
    });
    this.bind();
  }

  bind() {
    this.flow.assignBrowse(document.querySelector(this.button));
    this.flow.on('fileAdded', (file, event) => {
      store.commit('task/startLoadingFile');
      // console.log(file, event);
    });
    this.flow.on('fileSuccess', (file, message) => {
      store.commit('task/stopLoadingFile');

      if (this.task_id) {
        store.dispatch('task/fetchFiles', this.task_id);
      }

      if (JSON.parse(message).id) {
        this.fileIds.push(JSON.parse(message).id);
      }
    });
    this.flow.on('fileError', (file, message) => {
      store.commit('task/stopLoadingFile');
      // console.log(file, message);
    });
    this.flow.on('filesSubmitted', () => {
      // me.counter = 0;
      console.log('submitted');
      this.flow.upload();
    });
  }
}

export default UploadService;