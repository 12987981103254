import ApiService from './api.service';

const TaskService = {
  async storeTask(payload) {
    const requestData = {
      url: '/api/tasks',
      method: 'post',
      data: {
        data: payload,
      },
    };
    return ApiService.request(requestData);
  },

  async getTaskById(id) {
    const requestData = {
      url: `/api/tasks/${id}`,
      method: 'get',
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async updateTitle(taskId, title) {
    const requestData = {
      url: `/api/tasks/${taskId}/title`,
      method: 'put',
      data: {
        title,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async updateDescription(taskId, description) {
    const requestData = {
      url: `/api/tasks/${taskId}`,
      method: 'put',
      data: {
        description,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async getFiles(taskId) {
    const requestData = {
      url: `/api/tasks/${taskId}/files`,
      method: 'get',
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async deleteFile(taskId, fileId) {
    const requestData = {
      url: `/api/tasks/${taskId}/files/${fileId}`,
      method: 'delete',
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async getComments(taskId) {
    const requestData = {
      url: `/api/tasks/${taskId}/comments`,
      method: 'get',
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async storeComment(taskId, workerId, comment) {
    const requestData = {
      url: `/api/tasks/${taskId}/comments`,
      method: 'post',
      params: {
        workerId,
      },
      data: {
        comment,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async updateComment(commentId, comment) {
    const requestData = {
      url: `/api/comments/${commentId}`,
      method: 'put',
      data: {
        comment,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async deleteComment(commentId) {
    const requestData = {
      url: `/api/comments/${commentId}`,
      method: 'delete',
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async getExecutors(taskId) {
    const requestData = {
      url: `/api/tasks/${taskId}/workers`,
      method: 'get',
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async storeExecutors(taskId, executors) {
    const requestData = {
      url: `/api/tasks/${taskId}/workers`,
      method: 'post',
      data: {
        executors,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async storeTags(taskId, tags) {
    const requestData = {
      url: `/api/tasks/${taskId}/tags`,
      method: 'post',
      data: {
        tags,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async updateStatus(taskId, status) {
    const requestData = {
      url: `/api/tasks/${taskId}/status`,
      method: 'put',
      data: {
        status,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  
  async updateParent(taskId, parentId) {
    const requestData = {
      url: `/api/tasks/${taskId}/parent`,
      method: 'put',
      data: {
        parentId,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async storeProjectId(taskId, projectId) {
    const requestData = {
      url: `/api/tasks/${taskId}/project_id`,
      method: 'put',
      data: {
        projectId,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async storeSupport(taskId, support) {
    const requestData = {
      url: `/api/tasks/${taskId}/support`,
      method: 'put',
      data: {
        support,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async storePerformerId(taskId, performerId) {
    const requestData = {
      url: `/api/tasks/${taskId}/performer_id`,
      method: 'put',
      data: {
        performerId,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async storeDeadline(taskId, deadline) {
    const requestData = {
      url: `/api/tasks/${taskId}/deadline`,
      method: 'post',
      data: {
        deadline,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async storeStartAt(taskId, startAt) {
    const requestData = {
      url: `/api/tasks/${taskId}/start_at`,
      method: 'post',
      data: {
        start_at: startAt,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async updateIsArchive(taskId) {
    const requestData = {
      url: `/api/tasks/${taskId}/archive`,
      method: 'put',
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async setEstimate(taskId, estimate) {
    const requestData = {
      url: `/api/tasks/${taskId}/estimate`,
      method: 'put',
      data: {
        estimate,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async startTask(taskId) {
    const requestData = {
      url: `/api/tasks/${taskId}/start`,
      method: 'put',
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async endTask(taskId) {
    const requestData = {
      url: `/api/tasks/${taskId}/end`,
      method: 'put',
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async createRepeater(taskId, data) {
    const requestData = {
      url: `/api/tasks/${taskId}/repeater`,
      method: 'post',
      data,
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async deleteRepeater(taskId, estimate) {
    const requestData = {
      url: `/api/tasks/${taskId}/repeater`,
      method: 'delete',
      data: {
        estimate,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};

export default TaskService;