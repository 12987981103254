<template>
  <div class="task-index__attachments">
    <div class="task-index__attachments-head">
      <h3 class="task-index__attachments-label">Вложения</h3>
      <button class="task-index__attachments-button button upload-button">Добавить</button>
      <Loading :isLoading="isLoadingFile"/>
    </div>
    <div class="task-index__attachments-image">
      <div class="task-index__attachments-block">
        <TaskFilesList/>
      </div>
    </div>
  </div>
</template>

<script>
  import TaskFilesList from "./TaskFilesList.vue";
  import { mapState } from "vuex";
  import Loading from "../../ui/Loading.vue";
  export default {
    name: "TaskAttachments",
    components: {
      Loading,
      TaskFilesList
    },
    computed: {
      ...mapState('task', [
        'task',
        'isLoadingFile'
      ])
    },
    created() {

    }
  }
</script>

<style lang="scss">
  .task-index__attachments-head {
    position: relative;
  }
</style>