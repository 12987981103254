<template>
  <div class="task" ref="button"
       :class="{
          'task_no-estimate': noEstimate,
          'task_performer': isPerformer,
          'task_archive': isArchive
        }">

    <div class="task__main" @click="show">
        <div class="task__tags" v-if="task.tags && task.tags.length > 0">
            <ul class="task__tags-list">
                <li class="task__tags-list-item" v-for="tag in task.tags">
                    <div class="task__tags-list-badge" :style="{'background-color': tag.color}"></div>
                    <div class="task__tags-list-label">
                        {{ tag.name }}
                    </div>
                </li>
            </ul>
        </div>
      <div class="task__inner">
        <div class="task__kanban-options" v-if="!isEditable && !isNewTask">
          <a href="javascript:void(0);"
             class="task__kanban-options-item"
             @click.stop.prevent="onPencilClick"
          >
            <span class="task__kanban-options-icon">
              <img src="/static/images/svg/pen.svg" alt="">
            </span>
          </a>
          <a href="javascript:void(0);" class="task__kanban-options-item" @click.stop.prevent="openSelectPerformer">
              <span class="task__kanban-options-icon">
                <img src="/static/images/svg/executor.svg" alt="">
              </span>
          </a>
        </div>

        <div class="task__multi-icon" v-if="hasSubtasks">
            <IconMultitask/>
        </div>

        <div class="task__project" v-if="project">
          {{project.name}}
        </div>

        <div class="task__edit" v-if="isEditable">
          <textarea-autosize
            v-model="editName"
            autofocus
            :min-height="5"
          >
            {{task.name}}
          </textarea-autosize>
        </div>
        <p class="task__description" v-else>{{task.name}}</p>
        <TaskBottom :task="task"></TaskBottom>
      </div>
    </div>
    <div class="task__saver task-saver" v-if="isEditable">
      <div class="task-saver__button">
        <button class="button button_alert" @click="onSubmit">
          Сохранить
        </button>
      </div>
      <a href="javascript:void(0);" class="task-saver__closer" @click.prevent="onCrossClick">
        <img src="/static/images/svg/cross-dark-grey.svg" alt="">
      </a>
    </div>
  </div>

</template>

<script>
  import {mapState, mapGetters, mapActions} from "vuex";
  import TheAvatar from "../../ui/TheAvatar.vue";
  import TaskBottom from "../../parts/TaskBottom.vue";
  import PerformerSelectKanban from "./PerformerSelectKanban.vue";
  import IconMultitask from "../../../icons/IconMultitask";

  export default {
    name: "ProjectKanbanStatusTask",
    components: {
      IconMultitask,
      TheAvatar,
      TaskBottom,
    },
    props: {
      task: Object,
      isNewTask: Boolean,
    },
    computed: {
      ...mapState('worker', [
        'selectedWorkers',
        'workers'
      ]),
      ...mapState('user', [
         'user'
      ]),
      ...mapGetters({
        filters: 'filter/filters',
        getWorkerById: 'worker/getWorkerById',
        getProjectById: 'project/getProjectById'
      }),
      project() {
        if (this.task.project_id) {
          return this.getProjectById(this.task.project_id);
        }
        return null;
      },
      noEstimate() {
        return (!this.task.estimate && (this.task.status === 10 || this.task.status === 15))
      },
      isPerformer() {
        return this.task.performer_id === this.user.id;
      },
      isArchive() {
        return this.task.isArchive;
      },
      hasSubtasks() {
        return this.task.has_children;
      }
    },
    data() {
      return {
        isEditable: false,
        editName: '',
        isOpenExecutors: false,
      }
    },
    methods: {
      async show() {
        if (!this.isEditable && !this.isNewTask) {

          if (this.$route.name === 'projects_project') {
            this.$router.push({name: 'projects_project_task', params: {taskId: this.task.id}});
            return;
          }

          if (this.$route.name === 'projects_support') {
            this.$router.push({name: 'projects_support_task', params: {taskId: this.task.id}});
            return;
          }

          if (this.$route.params.projectId) {
            this.$router.push({name: 'project_task', params: {projectId: this.task.project_id, taskId: this.task.id}});
          } else {
            this.$router.push({name: 'task', params: {taskId: this.task.id}});
          }
        }
      },
      onPencilClick() {
        this.isEditable = true;
        this.editName = this.task.name;
        this.$emit('editTask');
      },
      onInput(event) {
        this.$emit('change', event.target.value);
      },
      async onSubmit() {
        if (this.editName !== '') {
          const workerIds = [];
          this.selectedWorkers.forEach((worker) => {
            workerIds.push(worker.id);
          });
          const payload = {
            taskId: this.task.id,
            title: this.editName,
            projectId: this.$route.params.projectId,
            workerIds
          };

          await this.$store.dispatch('project/updateTaskTitle', payload);

          if (this.$route.name === 'projects_project') {
            await this.$store.dispatch('project/fetchAllProjectTasks', );
          } else if (this.$route.name === 'projects_support') {
            await this.$store.dispatch('project/fetchAllSupportTasks');
          } else {
            await this.$store.dispatch('project/fetchTasks', {id: this.$route.params.projectId, filters: this.filters});
          }

          this.isEditable = false;
          this.$emit('editedTask');
        }
      },
      onCrossClick() {
        this.isEditable = false;
        this.$emit('editedTask');
      },
      openSelectPerformer() {
        Promise.all([
          this.$store.dispatch('task/fetchTask', this.task.id),
        ])
          .then(() => {
            this.$modal.show(
              PerformerSelectKanban,
              {},
              {width: '300', height: 'auto'},
            )
          })
          .catch(e => console.error(e));
      }
    },
    created() {
      this.editName = this.task.name;
    }
  }
</script>

<style lang="scss">
  .task {
    &__form {
      textarea {
        padding: 0;
        font-family: 'Roboto';
        resize: none;
        width: 100%;
        font-size: 15px;
        border: none;
      }
    }

    &__multi-icon {
      position: absolute;
      top: 7px;
      right: 10px;
      z-index: 1;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__main {
      //margin-bottom: 7px;
    }

    &__tags-list {
      display: flex;
      gap: 5px;
    }

    &__tags-list-item {
      flex: 1 0 auto;
      position: relative;
      margin-bottom: 4px;

      &:before {
        position: absolute;
        top: -10px;
        height: 15px;
        content: '';
        width: 100%;
        left: 0;
        right: 0;
      }

      &:hover .task__tags-list-label {
        opacity: 1;
      }
    }

    &__tags-list-badge {
      height: 4px;
      width: 100%;
      border-radius: 4px;
    }

    &__tags-list-label {
      background-color: #383737;
      color: #fff;
      font-size: 12px;
      padding: 6px 15px;
      position: absolute;
      pointer-events: none;
      border-radius: 2px;
      bottom: 5px;
      opacity: 0;
      transition: 0.3s all;
    }

    &__edit {
      textarea {
        padding: 0;
        font-family: 'Roboto';
        width: 100%;
        height: 15px;
        font-size: 15px;
        line-height: 1.3em;
        border: none;
        margin-bottom: 2px;
      }
    }

    &__saver {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
</style>