import FilterService from '../../services/filter.service';

const filterModule = {
  namespaced: true,
  state: {
    executorsSelected: [],
    ownersSelected: [],
    tagsSelected: [],
    deadlineSelected: [],
    isArchive: false,
    searchQuery: '',
  },
  getters: {
    filters(state) {
      return {
        executors: state.executorsSelected.map(executor => executor.id),
        owners: state.ownersSelected.map(owner => owner.id),
        tags: state.tagsSelected.map(tag => tag.id),
        archive: state.isArchive,
        searchQuery: state.searchQuery,
      };
    },
  },
  actions: {
    addExecutor({ commit }, payload) {
      commit('addExecutor', payload);
    },
    removeExecutor({ commit }, payload) {
      commit('removeExecutor', payload);
    },
    addOwner({ commit }, payload) {
      commit('addOwner', payload);
    },
    removeOwner({ commit }, payload) {
      commit('removeOwner', payload);
    },
    addTag({ commit }, payload) {
      commit('addTag', payload);
    },
    removeTag({ commit }, payload) {
      commit('removeTag', payload);
    },
    setIsArchive({ commit }, payload) {
      commit('setIsArchive', payload);
    },
    setSearchQuery({ commit }, payload) {
      commit('setSearchQuery', payload);
    },
  },
  mutations: {
    addExecutor(state, payload) {
      state.executorsSelected.push(payload);
      FilterService.setExecutors(state.executorsSelected);
    },
    removeExecutor(state, payload) {
      state.executorsSelected = state.executorsSelected
        .filter(executor => executor.id !== payload.id);
      FilterService.setExecutors(state.executorsSelected);
    },
    addOwner(state, payload) {
      state.ownersSelected.push(payload);
      FilterService.setOwners(state.ownersSelected);
    },
    removeOwner(state, payload) {
      state.ownersSelected = state.ownersSelected
        .filter(owner => owner.id !== payload.id);
      FilterService.setOwners(state.ownersSelected);
    },
    addTag(state, payload) {
      state.tagsSelected = [...state.tagsSelected, payload];
    },
    removeTag(state, payload) {
      state.tagsSelected = state.tagsSelected.filter(tag => tag.id !== payload.id);
    },
    setIsArchive(state, payload) {
      state.isArchive = payload;
    },
    setSearchQuery(state, payload) {
      state.searchQuery = payload;
    },
  },
};

export default filterModule;